@media only screen and (max-width: 375px) {
    .lang {
        display: none;
    }
}

.menu-item-lang {
    margin-left: 0px;
    padding: 0px 16px;
    min-height: 48px; 
    line-height: 48px;
}