.admin-section {
    color:#111111;
}
.admin-section-title {
    margin-bottom: 25px;
}
.admin-container {
    width: 100%!important;
    max-width: 100%!important;
}
.filter-button {
    margin:5px;
}
.badge {
    background-color: white;
    color: #666666;
}
.email-studio-date-left {
    padding-top: 3px;
}

.green-text {
    color: green;
    font-weight: bold;
}