/* Rend le carousel responsive en mobile */
.carousel-item > img {
  width: 100%;
}
.text-left-over-575px {
  text-align: left;
}
.text-right-over-575px {
  text-align: right;
}
.banner-text {
  padding-top: 15%;
  width: 400px;
}
.banner-home-winter-discovery-french {
  background: url('../../assets/headers_winter/discovery/desktop/slider_winter_pack_discovery_FR_1920x600.png') no-repeat center top;
}
.banner-home-winter-discovery-english {
  background: url('../../assets/headers_winter/discovery/desktop/slider_winter_pack_discovery_EN_1920x600.png') no-repeat center top;
}
.banner-home-winter-25gpwinter-french {
  background: url('../../assets/headers_winter/25gpwinter/desktop/winter_pack_25off_1920x600_FR.png') no-repeat center top;
}
.banner-home-winter-25gpwinter-english {
  background: url('../../assets/headers_winter/25gpwinter/desktop/winter_pack_25off_1920x600_EN.png') no-repeat center top;
}

.banner-home-winter-french {
  background: url('../../assets/headers_winter/regular/desktop/slider_winter_products_1920x600_FR.png') no-repeat center top;
}
.banner-home-winter-english {
  background: url('../../assets/headers_winter/regular/desktop/slider_winter_products_1920x600_EN.png') no-repeat center top;
}
.banner-home-summer-french {
    background: url('../../assets/headers_generic/homepage_header_french.jpg') no-repeat center top;
}
.banner-home-summer-english {
    background: url('../../assets/headers_generic/homepage_header_english.jpg') no-repeat center top;
}
.banner-home-winter-french,
.banner-home-winter-english,
.banner-home-winter-discovery-french,
.banner-home-winter-discovery-english,
.banner-home-summer-french,
.banner-home-summer-english,
.banner-home-winter-25gpwinter-french,
.banner-home-winter-25gpwinter-english {
  height: 31vw;
  width: 100%;
  background-size: contain!important;
  position: relative;
}

@media (max-width: 768px) {
  .banner-home-winter-discovery-french {
    background-image: url('../../assets/headers_winter/discovery/mobile/slider_winter_pack_discovery_FR_750x750.png');
  }
  .banner-home-winter-discovery-english {
    background-image: url('../../assets/headers_winter/discovery/mobile/slider_winter_pack_discovery_EN_750x750.png');
  }
  .banner-home-winter-25gpwinter-french {
    background-image: url('../../assets/headers_winter/25gpwinter/mobile/winter_pack_25off_750x750_FR.png');
  }
  .banner-home-winter-25gpwinter-english {
    background-image: url('../../assets/headers_winter/25gpwinter/mobile/winter_pack_25off_750x750_EN.png');
  }
  
  .banner-home-winter-french {
    background-image: url('../../assets/headers_winter/regular/mobile/slider_SP_winter_pack_750x750_FR.png');
  }
  .banner-home-winter-english {
    background-image: url('../../assets/headers_winter/regular/mobile/slider_SP_winter_pack_750x750_EN.png');
  }
  .banner-home-summer-french {
    background-image: url('../../assets/headers_generic/homepage_header_mobile_french.jpg');
  }
  .banner-home-summer-english {
    background-image: url('../../assets/headers_generic/homepage_header_mobile_english.jpg');
  }

  .banner-home-winter-french,
  .banner-home-winter-english,
  .banner-home-winter-discovery-french,
  .banner-home-winter-discovery-english,
  .banner-home-summer-french,
  .banner-home-summer-english,
  .banner-home-winter-25gpwinter-french,
  .banner-home-winter-25gpwinter-english {
    height: calc(100px + 70vw);
  }
  .container-countdown {
    padding-top: 40px !important;
  }
}
.box {
  float: left;
  margin: 0 5px;
  text-align: center;
}
.box span {
  display: block;
  border:1px solid rgb(255, 255, 255);
  margin-bottom: 10px;
  font-weight: 900;
}
.div-img {
  position:relative;
}
.div-img-2 {
  position: absolute;
  left:25%;
  margin-top: -170px;
}
.btn {
  border-radius: 3px;
}
.black-button {
  background-color: #000000;
  color:white;
  border: 1px solid #FFF100;;
}
.black-button:hover {
  border: 1px solid #FFF100;;
}
.tag-party {
  position: absolute;
  top: -100%;
  left: -50%;
  width:390px;
}
.button-tag-party {
  z-index: 99;
  position: absolute;
  right: 20px;
  top: 20px;
}
.container-countdown {
  color: white;
  padding: 20px 0px;
}
.first-step::after {
  content: "";
  top:10px;
  right: -45%;
  position: absolute;
  width: 90%;
  border-bottom: #63666A 1px solid;
}
.third-step::before {
  content: "";
  top:10px;
  left: -45%;
  position: absolute;
  width: 90%;
  border-bottom: #63666A 1px solid;
}
.text-div-banner2 {
  margin-left:10%;
}
.first-step,
.second-step,
.third-step {
  font-weight: 1000;
  font-size: 22px;
  margin-top: -5px;
}
.slider_jaune {
  background-image: url('../../assets/slider_party_jaune.jpg');
  background-position: center; 
}
.picto-home {
  width: 50%;
}
.explication-text {
  font-size: 1.24rem;
  font-weight: 500;
  line-height: 1.5;
}
.explication-container {
  padding-top:50px;
}

.explication-title {
  font-family: 'Gotham-Bold';
  font-size: 1.3rem;
  font-weight: 1000;
  margin-bottom: 30px;
}
.start-button {
  font-size: 24px !important;
  font-size: 18px !important;
  padding: 10px 30px;
}
.clickable:hover {
    cursor: pointer;
    text-decoration: underline;
}

.unboxing-left {
    float: right;
}
.unboxing-right {
    float: left;
}
.unboxing-left,
.unboxing-right {
    width: 100%;
}
.unboxing-left-col {
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.unboxing-right-col {
    padding-left: 10px;
    padding-top: 10px;
}
.trial-text {
    margin: auto;
    width: 70%;
    margin-bottom: 20px;
}
.whatsinside {
    max-width: 1940px;
    height: 520px;
    background-position: center center;
    margin: 0 auto;
    position: relative;
    text-align: left;
}
.whatsinside-en {
  background-image: url('../../assets/what__inside_gp_1940x520_en.png');

}
.whatsinside-fr {
  background-image: url('../../assets/what_inside_GP_1940x520_FR.png');
}
.whatsinside_title {
    text-align: left;
    font-family: 'Gotham-Bold';
    font-size: 2rem;
    font-weight: 1000;
    color: black;
}
.party-pack-title {
    text-align: left;
    font-family: 'Gotham-Bold';
    font-weight: 1000;
    color: #FFF100;
    text-shadow: 2px 2px #000000;
    margin-bottom: 20px;
    font-size: 2.2rem;
}
.whatsinside_subtitle {
    text-align: left;
    font-family: 'Gotham-Bold';
    font-size: 1.5rem;
    font-weight: 1000;
    margin-bottom: 5px;
    color:white;
}
.whatsinside_description {
  color: white;
  text-align: left;
  font-size: 1rem;
  margin-bottom: 20px;
}
.whats-inside-btn {
  padding: 10px 30px;
  vertical-align: bottom;
}
.seepacks-button {
  padding-top: 20px;
}


@media (max-width: 768px) {
    .unboxing-left-col,
    .unboxing-right-col {
        float: none;
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (min-width: 768px) {
  .box span {
      width: 100px;
      line-height: 100px;
      font-size: 48px;
 }
}
@media (min-width: 480px) and (max-width: 767px) {
  .box span {
      width: 90px;
      line-height: 90px;
      font-size: 48px;
 }
}
@media (max-width: 479px) {
  .box {
      font-size: 10px;
 }
  .box span {
      width: 60px;
      line-height: 60px;
      font-size: 36px;
 }
}
/* Alignement des 2 boutons au bas de la page selon la grosseur de l'écran*/
@media (max-width: 575px) {
  .first-step::after {
      height: 0;
      width: 0;
 }
  .third-step::before {
      height: 0;
      width: 0;
 }
  .text-left-over-575px {
      text-align: center;
 }
  .text-right-over-575px {
      text-align: center;
      margin-bottom: 5px;
 }
  .banner-text {
      width: 100%;
 }
  .tag-party {
      position: absolute;
      top: -80%;
      left: -37%;
      width:325px;
 }
 .video_container {
     padding: 15px 0px !important;
 }
}
@media (max-width: 340px) {
  .tag-party {
      width: 300px;
 }
}

@media (max-width: 768px) {
  .button-tag-party {
    display: none;
  }
}

/* Ajustement pour les petits écrans*/
@media (max-width: 392px) {
  .modalTopText {
    font-size:1.20em;
  }
}

@media (min-width: 968px) and (max-width: 1125px) {
  .start-button {
    font-size: 12px!important;
    padding: 10px 20px;
  }
}
@media (min-width: 769px) and (max-width: 967px) {
  .start-button {
    font-size: 12px !important;
    padding: 10px 10px;
  }
}
.video_container {
  padding: 30px;
  padding-top: 0px;
}
.home-button-left {
    float: right;
}
.home-button-right {
    float: left;
}
@media (max-width: 767px) {
    .home-button-left {
        float: none;
        margin-bottom: 10px;
    }
    .home-button-right {
        float: none;
    }
  }

.reference-alert {
    border-radius: 0px;
    background-color: #fff100;
    margin-bottom: 0px;
}

.reference-link:hover {
    cursor: pointer;
}

* {-webkit-font-smoothing: antialiased;}

@media (max-width: 991px) {
    .trial-text {
        width: 90%;
    }
}
@media (min-width:768px) and (max-width: 1200px) {
  .whatsinside {
    display: none;
  }
}
@media (min-width:1200px) {
.whatsinside {
  height:520px;
  }
}
@media (max-width: 767px) {
  .whatsinside {
    background-repeat: no-repeat;
    background-size:cover;
  }
  .whatsinside-en {
    background-image: url('../../assets/what_inside_GP_767x650_en.png');
  }
  .whatsinside-fr {
    background-image: url('../../assets/what_inside_GP_767x650_fr.png');
  }
  .wi-container {
    padding:0 !important;
    margin:auto;
  }
  .whatsinside_description {
    display: none !important;
  }
  .whatsinside_title {
    font-size: 1.7rem;
    text-align: center;
  }
  .whatsinside_subtitle {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .party-pack-title{
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 0px;
  }
  .seepacks-button {
    padding-top: 0px;
    text-align: center;
  }
}
@media (max-width: 368px) {
  .whatsinside_subtitle {
    display: none;
  }
  .seepacks-button {
    padding-top:20px;
  }
}
.picto-description {
  font-size: 18px;
  text-align: left;
}
.img-picto {
  width:100%;
}
.home-hr {
  margin:0px;
}
@media (max-width: 575px) {
  .img-picto {
      width: 30%;
  }
  .home-gp-steps{
      display:none;
  }
}
@media (max-width: 991px) {
  .picto-description {
      padding:10px;
      text-align:center;
  }
}

.homepage-trial-button {
  color:white;
  font-weight: 800;
  font-size: 24px;
}
.homepage-trial-button-summer {
  background-color: #00aeef;
}
.homepage-trial-button-winter {
  background-color: #144262;
}