@font-face {
  font-family: Gotham-Book;
  src: url('./fonts/gothambook-webfont.woff2') format('woff2'),
  url('./fonts/gothambook-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Gotham-Bold;
  src: url('./fonts/gothambold-webfont.woff2') format('woff2'),
  url('./fonts/gothambold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Gotham-Black;
  src: url('./fonts/gothamblack-webfont.woff2') format('woff2'),
  url('./fonts/gothamblack-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Gotham-Ultra;
  src: url('./fonts/gothamultra-webfont.woff2') format('woff2'),
  url('./fonts/gothamultra-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: BTBD;
    src: url('./fonts/back_to_black_demo.woff2') format('woff2'),
    url('./fonts/back_to_black_demo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
body {
  margin: 0;
  font-family: 'Gotham-Book', sans-serif;
  color:#ffffff;
  background-color: #000000;
}
main {
  min-height: 85vh;
}
h1, h2, h3, p {
  margin: 0;
}
a {
  color:inherit;
}
.page {
  min-height: 85vh;
  max-width: 1044px;
  margin: 0 auto;
  margin-top: 64px;
}
.section {
  position: relative;
}
.form {
  margin: 16px;
}
.card {
  margin: 16px;
}
.background-image {
  background-repeat:  no-repeat !important;
  background-size:  cover !important;
}
/* Fields */
.field-container {
  display: flex;
  flex-flow: row wrap;
  padding: 16px
}
.field {
  flex: 1 1 auto;
  margin: 0 8px;
}
.checkbox-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 16px
}
.checkbox {
  width: auto !important;
  margin: 0 8px;
}
.date {
  display: flex;
  align-items: flex-end;
}
/* Buttons */
.button-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-top: 5px;
}
.button {
  flex: 1 1 auto;
  margin: 8px;
}
.delete-button > button {
  background-color: rgba(213, 0, 0, 1) !important;
  color: #ffffff !important;
}
.delete-button * {
  color: #ffffff !important;
}
.delete-button > button:hover  {
  background-color: rgba(213, 0, 0, 0.5) !important;
}
.error {
  font-weight: bold;
  color: rgb(244, 67, 54);
  margin: 8px 16px;
}
/* Image Transistions */
.fadein-appear {
  opacity: 0;
}
.fadein-appear.fadein-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 2s ease-in;
}
.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 2s ease-in;
}
a:hover {
  color: #000000;
  font-weight: 900;
}
.link-white-back:hover {
  color: black;
}
.white-background {
  background-color: white;
  color: black;
}
.yellow-button {
  background-color: #FFF100;
  color:black;
  font-weight: 900;
  font-size: 17px;
}
.yellow-button:hover {
  color:black;
  background-color: #999999;
}
.background-image-header {
  height: 180px;
}
.container-top-margin {
  padding-top: 75px;
  padding-bottom: 20px;
}
.background-yellow {
  background-color: #fff100;
}
.dont-spoil-text {
  font-size: 1.24rem;
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (max-width: 575px) {
  .background-image-header {
      background-position: -50px;
  }
}

@media only screen and (max-width: 406px) {
  .background-image-header {
      background-position: 9.5%;
  }
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
}
@media (max-width: 991px) {
  .desktop-only-lg {
    display: none;
  }
}