.header-number {
    background-color: #fff100;
    color: #000000;
    height: 50px;
    width: 50px;
    margin: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7em;
    font-weight: 500;
    margin-top: 24px;
}

.modal-content {
    border: 0;
}

.choice-box {
    color: #000000;
    background-color: #ffffff;
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    border: 1px solid #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    transition: border-width 0.25s, border-color 0.25s, transform 0.25s;
    cursor: pointer;
    margin-bottom: 20px;
}

.float-right-over-575 {
    float: right;
}

.float-left-over-575 {
    float: left;
}

.progress-bar-box {
    padding: 10px 150px 10px 150px;
}

.trial-box-reminder-row {
    display: block;
    margin-bottom: 25px;
}

.boxsub-trial {
    font-weight: 700;
}
.boxsub-trial.boxsub-left {
    font-size: 18px;
    font-family: 'Gotham-Bold';
}

@media (min-width: 398px) and (max-width: 534px) {
    .trial-box-reminder-row {
        margin-bottom: 30px;
    }
}

@media (max-width: 397px) {
    .trial-box-reminder-row {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .float-right-over-575 {
        float: none;
    }
    .float-left-over-575 {
        float: none;
    }
}

@media (min-width: 767px) {
    .trial-modal {
        -webkit-transform: translate(0, 65%) !important;
        transform: translate(0, 65%) !important;
    }
}

@media only screen and (max-width: 575px) {
    .float-right-over-511 {
        float: none;
    }
}

@media only screen and (max-width: 768px) {
    .progress-bar-box {
        padding: 10px 10px 10px 10px;
    }
}

/* animation hover */

.choice-box:hover {
    border: 5px solid #999999;
    transform: scale(1.1);
}

/* Apparence du choix sélectionné */

.selected-reponse {
    border: 5px solid #fff100 !important;
    transform: scale(1.05);
}

.progress-bar {
    background-color: #fff100 !important;
}

.small-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 25%;
    height: 50px;
    float: left;
    border: solid 1px white;
    margin-bottom: 10px;
    cursor: pointer;
    transition: border-width 0.25s, border-color 0.25s, transform 0.25s;
}

.small-box:hover {
    border: 5px solid #cccccc;
    transform: scale(1.05);
}

.small-row {
    margin: 0 auto;
    width: 201px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.motif_background {
    height: 200px;
    background-image: url('../../assets/motif_party.png');
    background-position: center;
}

.trial-modal {
    color: black;
    background-color: rgba(255, 241, 0, 0.9);
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    margin-top: 70px;
}

.centered-content {
    background: transparent;
}

.modal-content {
    background-color: transparent;
}

.final-pack-choice {
    min-width: none;
    max-width: none;
    min-height: none;
    max-height: none;
    background-color: black;
    position: relative;
}

.final-pack-image {
    width: 100%;
    height: auto;
}

.final-pack-explain {
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
}

.final-pack-vary {
    font-size: 14px;
}

.pack-note {
    position: absolute;
    bottom: 1px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

#next-arrow {
    /* Safari 4.0 - 8.0 */
    -webkit-animation: glowing 1s infinite;
    animation: glowing 1s infinite;
}

/* Safari 4.0 - 8.0 */

@-webkit-keyframes glowing {
    0% {
        box-shadow: 0 0 10px 4px #fff100;
    }
    50% {
        box-shadow: 0 0 1px 4px #fff100;
    }
    100% {
        box-shadow: 0 0 10px 4px #fff100;
    }
}

/* Standard syntax */

@keyframes glowing {
    0% {
        box-shadow: 0 0 10px 4px #fff100;
    }
    50% {
        box-shadow: 0 0 5px 1px #fff100;
    }
    100% {
        box-shadow: 0 0 10px 4px #fff100;
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    .pack-note {
        font-size: 13px;
        bottom: 15px;
    }
}

@media (max-width: 768px) {
    .pack-note {
        bottom: 0px;
        padding: 0px 5px;
        font-size: 12px;
        background-color: #000000;
        color: #cccccc;
    }
}

@media (max-width: 454px) {
    .pack-note {
        font-size: 11px;
    }
}

.link-previous {
    text-decoration: underline;
    font-size: 18px;
    font-weight: bold;
}

.link-previous:hover {
    color: yellow;
}

.bed-explain-section {
    margin-top: 25px;
    margin-bottom: 15px;
    padding: 5px;
}

.save-over-section {
    font-size: 24px;
    font-family: Gotham-Bold;
}