.form-line {
    margin-bottom: 10px;
}
.faq-title {
    text-decoration: underline;
}
.faq-subtitle {
    font-weight: bold;
}
.faq-content {
    margin-bottom: 20px;
}
.faq-container a {
    text-decoration: underline;
}
.underlineLink {
    cursor: pointer;
    text-decoration: underline;
}

.faq-note {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.holiday-away-message {
    font-weight: bolder;
    color: darkred;
    text-shadow: 2px 2px 2px rgba(255, 0, 0, 0.25);
}
