.OrderCartList {
  padding: 4px
}
.header-checkout {
  height:180px;
  background-position: center;
}
.row{
  margin-right: 0px; 
  margin-left: 0px;
}
/* Credit Card */
.credit-card-list {
  list-style: none;
  padding: 0;
  margin: 16px 0;
}
.credit-card-list li {
  float: left;
  color: #aaa;
  font-size: 26px;
}
.credit-card-list li:not(:last-child) {
  margin-right: 8px;
}
 .active {
  color: white;
}
.alert {
  margin-top: 24px;
}
.credit-card-list h5 {
  margin-top: 0px;
}

.textfield-coupon {
  width : 39%
}
@media (min-width: 414px) {
  .textfield-coupon {
    width: 69%;
  }
}