.topImg {
    width:100%;
    max-width: 750px;
    height: auto; 
}

.unsubImg {
    width:100%;
    height: auto;
}

.unsubContainer {
    padding: 0px;
    margin-top: 70px;
}
@media (min-width: 767px) {
    .unsub-button {
        float: right;
    }
}    