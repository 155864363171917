.Buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.Buttons > div {
  margin: 8px !important;
}

svg {
  vertical-align: inherit
}