.notFound-picto {
    padding-top: calc(64px + 75px);
}
.notFound-title {
    padding: 25px;
}

@media (max-width: 575px) {
    .notFound-picto {
        width: 100%;
    }
    .notFound-title {
        padding-top: 0px
    }
}