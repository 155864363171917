
/**** Mobile layout ****/
@media (max-width: 375px) {
  .largerThanIphone375 {
    display: none !important;
  }
}
@media (max-width: 667px) {
  .largerThanIphone667 {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .largerThanIpad768 {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .largerThanIpad1024 {
    display: none !important;
  }
}
@media (max-width: 1366px) {
  .largerThanIpad1366 {
    display: none !important;
  }
}
@media (max-width: 1920px) {
  .largerThan1920 {
    display: none !important;
  }
}

.fa.fa-shopping-cart {
  font-size: 20px !important;
}

.drawer-brand {
  display: flex;
  cursor: pointer;
  font-size: 24px;
  height: 64px;
  margin-bottom: 4px;
  max-height: 64px;
  padding-left: 16px;
}

.drawer-brand img {
  max-height: 64px;
  width: inherit;
}

.drawer-user {
  padding: 16px;
  margin: 8px 0 0;
}

.appbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.appbar-navigation-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
}

.appbar-navigation {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 64px;
}

.appbar-phone-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  line-height: initial;
  padding-top: 3px;
  margin-bottom: -3px;
}

.appbar-phone {
  text-decoration: none;
  color: inherit;
  font-size: 30px
}

.active-nav {
  text-decoration: underline #000000 !important;
}

.header-brand {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top:8px;
  font-size:32px;
}

.header-brand-image {
  margin-right: 8px
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  align-self: flex-start;
}

.drawer-image {
  max-height: 64px;
}

.drawer-brand-name {
  align-self: center;
}

header svg {
  fill: inherit !important;
}

.appbar-brand {
  color:black;
}

.appbar-brand:hover {
  color:black;
}

.nav-header:hover {
  border-bottom: 2px solid !important;
}

button:focus { 
  outline:none;
}

.underlineHover:hover{
  text-decoration: underline;
}

.blue-star {
    color: #4fc8f4;
}

@media (max-width: 360px) {
  .header-brand {
    margin-top:13px;
    font-size:24px;
  }
}