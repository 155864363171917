.signup-modal {
  background-color: #FFF100;
  color: black;
  align-items: center;
  justify-content: center;
  text-align: center;
  border : 1px solid black;
}
.popup-background {
  background-color:white;
  margin-left: auto;
}
.modal-background {
  background-image: url('../../assets/popup_image.jpg');
  height:100%;
  width:100%;
  background-repeat: no-repeat;
}
.modal-content {
  height:100%;
  width:100%;
  display:table;
  text-align:center;
}
.black-topline {
  height:15px;
  margin: 0px 100px;
  background-color:black;
}
.popup-text {
  font-style: italic;
  margin-bottom: 5px;
}
.sub-text {
  margin: 0px 40px 10px;
  font-size:1rem;
  font-weight: 1000;
}
.discount-text {
  font-size:2rem;
  font-family: 'Gotham-Bold';
}
.nextpack-text {
    font-size: 1.2rem;
    font-weight: 700;
    font-family: 'Gotham-Bold';
}
.popup-button {
  background-color: #FFF100;
  color:black;
  font-weight: 900;
  font-size: 17px;
  border-style: solid;
  border-color: #fff100;
  width: 150px;
  font-style: italic;
  font-family: 'Gotham-Bold';
  cursor: pointer;
}
.email-input {
  background-color:#e9e8e9;
  width: 100%;
  border:none;
  padding-left:5px;
  font-family: 'Gotham-Bold';
  font-size:0.875rem;
}
.nothanks-text {
  color:#8d8d8d;
  font-size: 0.8rem;
}
.nothanks-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.error-text {
  color:red;
  font-size:0.8rem;
  font-style: italic;
  font-family: 'Gotham-Bold';
  padding-top: 5px;
}
.thanks-text {
  color: #3bddde;
  font-size:1rem;
  font-family: 'Gotham-Bold';
  padding-top: 5px;
}
.input-wrapper {
  margin: 20px 20px 0px 20px;
}
/* Support pour les différents browsers */
.email-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-style: italic;
  font-family: 'Gotham-Bold';
  font-size:0.8rem;
}
::-moz-placeholder { /* Firefox 19+ */
  font-style: italic;
  font-family: 'Gotham-Bold';
  font-size:0.8rem;
}
:-ms-input-placeholder { /* IE 10+ */
  font-style: italic;
  font-family: 'Gotham-Bold';
  font-size:0.8rem;
}
:-moz-placeholder { /* Firefox 18- */
  font-style: italic;
  font-family: 'Gotham-Bold';
  font-size:0.8rem;
}

@media (min-width: 767px) {
  .signup-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
  }
}
@media (min-width: 992px) {
  .popup-background {
    width: 50%;
  }
  .signup-modal {
    height: 400px;
    width: 740px;
    background-color: transparent;
    
  }
  .modal-background {
    padding:40px;
    display: table-cell;
    vertical-align: middle;
  }
}
@media (max-width: 991px) {
  .popup-background {
    margin:20px;
  }
  .modal-background  {
    padding-top: 300px;
  }
}

