.faded-black-background{
    padding: 10px;
    background-color: rgba(0,0,0,0.5);
    -webkit-transition: background-color 0.5s; /* Safari */
    transition: background-color 0.5s;
}
.faded-black-background:hover{
    background-color: rgba(0,0,0,0.7);
}
.banner-top {
    padding-top: 70px;
    height: 510px;
}
.pack-container {
    padding: 20px;
}
.explication_more {
    color: white;
}
.learn-more-step {
    margin-bottom: 30px;
}
.learn-more-two-color {
    background-image: linear-gradient(0, #fff100 29%, #fff 0%, #fff 71%);
    background-image: -ms-linear-gradient(bottom, #fff100 29%, #fff 0%, #fff 71%);
}
.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid #000;
    margin: 0 auto;
}

.calendar-explication {
    text-align: left;
    color: black;
    font-size: 20px;
    line-height: 2.5rem;
    font-weight: 550;
}

.calendar-explication-box {
    width: 750px;
    padding: 20px 30px;
    background-color: #e9e8e9;
}
.link {
    text-decoration: underline;
    transition: all 0.3s;
}
.link:hover {
    cursor: pointer;
    font-weight: 900;
}
.pack-description {
    color: black;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
}
.pack-price {
    font-size: 36px;
    font-weight: bolder;
    font-family: Gotham-Bold;
    margin-bottom: 10px;
}
.pack-price-normal {
    font-family: 'Gotham-Book', sans-serif;
}
.pack-button-container {
    margin-bottom: 10px;
}
.pack-button {
    background-color: black;
    color: white;
    font-size: 14px;
}
.pack-image {
    cursor: pointer;
    -webkit-transition: transform 0.5s;
    transition: transform 0.5s;
}
.pack-image:hover {
    transform: scale(1.1);
}
#learnmore_modal {
    max-width: 60%;
    margin-top: 70px;
    border: 1px solid black;
}
.learn-more-white-title {
    font-weight: 900;
    font-size: 26px;
    padding: 24px 0px;
}
.learnmore-slider-img {
    height: 320px;
    margin: auto;
}
.full-selection-left-arrow,
.full-selection-right-arrow {
    position: absolute;
    font-size: 48px;
    opacity: 0.5;
    top: 10vw;
    cursor: pointer;
}
.full-selection-right-arrow {
    left: 55vw;
}
.pack-value {
    color: black;
    margin-bottom: 10px;
    font-weight: bold;
}
.pack-save-40 {
    position: absolute;
    transform:
        translateX(-120px)
        rotateZ(-10deg)
        translateY(-40px);
}

@media (max-width: 990px) {
    .banner-top {
        padding-top: 30px;
        padding-bottom: 20px;
        height: auto;
    }
    .pack-image:hover {
        transform: scale(1.025);
    }
    .pack-save-40 {
        transform:
            translateX(-450px)
            rotateZ(-10deg)
            translateY(645px);
    }
}
@media (max-width: 767px) {
    .calendar-explication-box {
        max-width: 100%;
        margin: 0px;
    }
}
@media (max-width: 575px) {
    .calendar-explication {
        font-size: calc(3vw + 5px);
        line-height: calc(3vw + 10px);
    }
    .calendar-line {
        margin-bottom: 10px;
    }
    #learnmore_modal {
        max-width: 100%;
    }
    .full-selection-right-arrow {
        left: 82vw;
    }
    .full-selection-left-arrow,
    .full-selection-right-arrow {
        top: 15vw;
    }
}
@media (max-width: 480px) {
    .pack-image {
        width: 100%;
    }
    .pack-save-40 {
        position: relative;
        transform:
            translateX(-80px)
            rotateZ(-10deg)
            translateY(10px);
    }
}
@media (min-width: 992px) {
    #pack_discovery_0 {
        margin-top: 110px;
    }
    #pack_superpartypackbed_0 {
        margin-top: 122px;
    }
    #pack_discovery_1 {
        margin-top: 62px;
    }
    #pack_superpartypackbed_1 {
        margin-top: 82px;
    }
    .pack-image {
        margin-bottom: 24px;
        width: 100%;
    }
    .pack-value-other {
        margin-bottom: 64px;
    }
}

.go-button {
    background-color: black;
    color: white;
    font-size: 24px;
    width: 175px;
}

.blue-button {
    background-color: #42b9f4;
    color:black;
    font-weight: 900;
    font-size: 24px;
}
@media (max-width: 330px) {
    .blue-button {
        font-size: 12px;
    }
}
@media (min-width: 331px) and (max-width: 359px) {
    .blue-button {
        font-size: 14px;
    }
}
@media (min-width: 360px) and (max-width: 405px) {
    .blue-button {
        font-size: 16px;
    }
}
@media (min-width: 406px) and (max-width: 505px) {
    .blue-button {
        font-size: 18px;
    }
}
.previous-content {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
