.picto-text{
    font-size: 22px;
    text-align: left;
    padding-left: 20px;
}
.description-text {
    font-size: 18px;
}

.description-title{
    font-weight: 1200;
    font-size: 26px;
    padding-top:0px;
    padding-bottom: 24px;
}
.description-image{
    width: 100%;
}
.description-bloc {
    padding:15px;
    height: 100%
}
.quality-title {
    margin-top:auto;
    margin-bottom: auto;
    font-weight: 900;
    font-size: 30px;
    color: black;
}
.gray-background {
    background-color: #e9e8e9;
    color: black;
  }
.passionate-team {
    height:auto;
}
.whygp-hr {
    border-top: 2px #000 solid;
    border-bottom: 2px #000 solid;
}
.passionate-team-title {
    font-weight: 900;
    font-size: 26px;
    color:black;
    padding:15px;
    margin:auto;
}
.quality-img {
    margin:auto;
}
.quality-title {
    margin:auto;
}
@media (max-width: 991px) {
    .description-col {
        padding:25px
    }
    .picto-text {
        text-align:center;
    }
}
@media (max-width: 452px) {
    .row-b1b-quality {
        padding:20px;
    }
}