.referral-title {
    margin-top : 20px;
    margin-bottom: 40px;
}
.referral-subtitle {
    font-weight: bold;
    margin-top : 20px;
    margin-bottom: 10px;
    font-size: 18px;
}
.referral-content {
    margin-bottom: 20px;
}
.referral-gif {
    width:100%;
    max-width:500px;
}
.referral-button {
    background-color: #fff100;
    width: 200px;
    color: black;
    border: none;
    font-weight: bold;
}