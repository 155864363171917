a {
  text-decoration: none;
  transition: color 0.3s;
}
.hover-white:hover {
  color:white;
}
.footer-brand {
  padding: 8px 0;
  text-align: center;
}

.footer-image {
  display: flex;
  padding: 8px 0;
}

.footer-paper {
  padding: 32px 16px 24px;
  color: #ffffff;
}

.footer-social-media {
  display: flex;
  justify-content: center;
  padding: 8px 0;
}

.footer-social-media>a {
  margin: 0 16px;
}

.footer-links-left {
  text-align: right;
}

@media only screen and (max-width: 575px) {
  .footer-paper {
    text-align: center;
  }
  .footer-links-left {
    text-align: center;
  }
}