.coupon {
  position:relative;
  padding: 10px;
  border:1px darkgray solid;
  border-radius: 3px;
  margin: 5px 0;
}
.coupon:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius:3px;
  border-bottom-left-radius: 3px;
  background-color:#FFF100;
}
#cart_left_section {
  border-right: 1px solid black;
}
.total-right-section {
  text-align: right;
}
.cart-header-row {
  padding: 6px 0px;
  background-color: black;
  color: white;
}
#cart_header {
  margin: 24px 0px;
}
.cart-explaination {
  background-color: #eee;
  padding: 20px 36px;
  margin-bottom: 15px;
}
.cart-explaination-blue {
    background-color: #00aeef;
    color: white;
}
.setTrial{
  width: 100%;
  margin-top: 5px;
  text-align: center;
  color: black;
  background:none;
  border:none
}
.setTrial:hover, .setTrial:active{
  font-weight: bold;
  color: black!important;
  background:none!important;
}
@media (max-width: 768px) {
  #cart_left_section {
    border-right: none;
  }
  .desktop-only {
    display: none;
  }
  #cart_header {
    margin-bottom: 0px;
  }
}

@media (max-width: 575px) {
  .cart-item-image-container {
    text-align: center;
    margin-bottom: 18px;
  }
}

@media (min-width: 576px) {
  .cart-item-image {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cartButton {
      font-size:12px!important;
  }
}