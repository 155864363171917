.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.clickable:hover{
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
}