.partner-logo {
    width:100%;
    height: auto;
    max-width: 200px;
}
.purebites {
    margin-bottom: 15px;
}
.logo-wrapper {
    margin: auto;
}