.title-example {
    text-align: center;
}

.example-image {
    width: 100%;
    max-width: 900px;
    height: auto;
    -webkit-transition: transform 0.5s;
    transition: transform 0.5s;
}

.example-image:hover {
    transform: scale(1.1);
}

.example-section {
    padding: 30px 0px;
    text-align: center;
}

.example-pack-title {
    font-style: italic;
    margin-bottom: 25px;
}

.choice-box-example {
    color: #000000;
    background-color: #ffffff;
    min-width: 100px;
    max-width: 100px;
    min-height: 112px;
    max-height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    transition: border-width 0.25s, border-color 0.25s, transform 0.25s;
    cursor: pointer;
    margin-bottom: 10px;
}

.example-picto {
    width: 80%;
    height: auto;
}

.picto-wrapper {
    width:100%;
    text-align: center;
}

.slider_jaune_example {
    background-image: url('../../assets/slider_party_jaune.jpg');
    background-position: center;  
}

.example-bottom-action {
    display: block;
    align-items: center;
    justify-content: center;
    color:white;
    margin: auto;
    text-align: center;
    padding-top: 30px;
    width:315px;
}

.example-bottom {
    display: flex;
    height: 300px;
}

.example-button {
    background-color: #000000;
    border: solid 1px yellow;
}