.tv-logo {
    width:100%;
    height: auto;
    max-width: 150px;
    transition: transform 0.25s;
    /*cursor: pointer;*/
}
/*
.tv-logo:hover {
    transform: scale(1.1);
}
*/
.tv-left {
    float: right;
}

.tv-right {
    float: left;
}

.logo-wrapper {
    margin: auto;
}

.video-modal {
    margin-top: 70px;
    width:96%;
    max-width: 767px;
    background: #666666;
    border: solid black 1px;
}

@media (min-width: 767px) {
    .video-modal {
        -webkit-transform: translate(0, 15%) !important;
        transform: translate(0, 15%) !important;
    }
}

@media (max-width: 768px) {
    .tv-left,
    .tv-right {
        float: none;
    }
}